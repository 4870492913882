import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { Router } from "@angular/router";
import { EncryptDecrypt } from "./encrypt-decrypt";
import { HandleHTTPErrorService } from "../services/handle-http-error.service";
import { AppSettingsService } from "../services/app-settings.service";
import { AuthService } from "../services/auth";

@Injectable()
export class gvaSystemInterceptor implements HttpInterceptor {
     userData = localStorage.getItem('gvaSystem');
     authToken = null;

     constructor(
          private handleHTTPErrorService: HandleHTTPErrorService,
          private router: Router,
          private appSettingsService: AppSettingsService,
          private authService: AuthService,
     ) { }

     intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
          this.userData = localStorage.getItem('gvaSystem') ? localStorage.getItem('gvaSystem') : null
          if (this.userData) {
               this.authToken = JSON.parse(EncryptDecrypt.decryptWithKey(localStorage.getItem('gvaSystem')))?.token ? JSON.parse(EncryptDecrypt.decryptWithKey(localStorage.getItem('gvaSystem')))?.token : null;
          }
          if (this.authToken) {
               req = req.clone({
                    setHeaders: {
                         Authorization: `Bearer ${this.authToken}`
                    }
               });
          } else {
               req = req.clone();
          }
          return next.handle(req).pipe( 
               catchError(
                    (err: HttpErrorResponse)=>{
                         if (err instanceof HttpErrorResponse){
                              this.handleHTTPErrorService.handleError(err);
                         }
                         return throwError(() => err);
                    }
               )
          );
     }
}
